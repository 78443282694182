import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel from "@/__main__/data-model.mjs";

const Patch = {
  patch: String,
} satisfies ModelDefinition;

export const PatchVersionValidator = createModel(Patch);

export type LatestPatch = FromModel<typeof PatchVersionValidator>;
