import type { DeepReadonly } from "ts-essentials";

import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Optional } from "@/__main__/data-model.mjs";

const Item = {
  name: Optional(String),
  _class: Optional(String),
  m_eAbilityType: Optional(String),
  m_eItemSlotType: Optional(String),
  m_iItemTier: Optional(String),
  m_strAbilityImage: Optional(String),
  m_strMoviePreviewPath: Optional(String),
  _multibase: Optional(String),
} satisfies ModelDefinition;

export const AbilitiesItemsValidator = createModel(
  Mapped({
    key: String, // Item Id
    value: Item,
  }),
);

export type AbilitiesItems = DeepReadonly<
  FromModel<typeof AbilitiesItemsValidator>
>;
