import getData from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import type { AbilitiesItems } from "@/game-deadlock/models/abilitiesItems.mjs";
import { AbilitiesItemsValidator } from "@/game-deadlock/models/abilitiesItems.mjs";
import type { AbilitiesItemsLocalized } from "@/game-deadlock/models/abiltiesItemsLocalized.mjs";
import { AbilitiesItemsLocalValidator } from "@/game-deadlock/models/abiltiesItemsLocalized.mjs";
import type { Heroes } from "@/game-deadlock/models/hero.mjs";
import { HeroesValidator } from "@/game-deadlock/models/hero.mjs";
import type { LatestPatch } from "@/game-deadlock/models/latest-patch.mjs";
import { PatchVersionValidator } from "@/game-deadlock/models/latest-patch.mjs";
import { devError } from "@/util/dev.mjs";

export default async function fetchData(): Promise<void> {
  await Promise.all([
    fetchHeroes(),
    fetchAbilitesItems(),
    fetchLocalAbilitiesItems(),
  ]);
}

function _fetchLatestPatch(): Promise<LatestPatch> {
  return getData(
    `${appURLs.UTILS_STATIC}/deadlock/latest_patch`,
    PatchVersionValidator,
    ["deadlock", "patchLatest"],
    {
      shouldFetchIfPathExists: true,
    },
  );
}

function fetchHeroes(): Promise<Heroes | undefined> {
  try {
    return getData(
      `${appURLs.UTILS_STATIC}/deadlock/heroes`,
      HeroesValidator,
      ["deadlock", "heroes"],
      {
        shouldFetchIfPathExists: true,
      },
    );
  } catch (e) {
    devError("Deadlock: Failed to fetch static data for heroes", e);
  }
}

function fetchAbilitesItems(): Promise<AbilitiesItems | undefined> {
  try {
    return getData(
      `${appURLs.UTILS_STATIC}/deadlock/abilities_items`,
      AbilitiesItemsValidator,
      ["deadlock", "abilitiesItems"],
      {
        shouldFetchIfPathExists: true,
      },
    );
  } catch (e) {
    devError("Deadlock: Failed to fetch static data for abilties and items", e);
  }
}

function fetchLocalAbilitiesItems(): Promise<
  AbilitiesItemsLocalized | undefined
> {
  const language = "en-us";

  try {
    return getData(
      `${appURLs.UTILS_STATIC}/deadlock/abilities_items_${language}`,
      AbilitiesItemsLocalValidator,
      ["deadlock", "abilitiesItemsLocal", language],
      {
        shouldFetchIfPathExists: true,
      },
    );
  } catch (e) {
    devError(
      "Deadlock: Failed to fetch static data for abilties and items localization",
      e,
    );
  }
}
