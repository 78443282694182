import type { DeepReadonly } from "ts-essentials";

import type { FromModel } from "@/__main__/data-model.mjs";
import createModel, { Mapped } from "@/__main__/data-model.mjs";

export const AbilitiesItemsLocalValidator = createModel(
  Mapped({
    key: String, // Item Id
    value: String,
  }),
);

export type AbilitiesItemsLocalized = DeepReadonly<
  FromModel<typeof AbilitiesItemsLocalValidator>
>;
